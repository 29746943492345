/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import n from "../Extent.js";
import { create as t } from "./aaBoundingRect.js";
function r(n) {
  return n;
}
function i(n = D) {
  return r([n[0], n[1], n[2], n[3], n[4], n[5]]);
}
function a(n, t, r, a, u, e, m = i()) {
  return m[0] = n, m[1] = t, m[2] = r, m[3] = a, m[4] = u, m[5] = e, m;
}
function u(n, t = i()) {
  return t[0] = n.xmin, t[1] = n.ymin, t[2] = n.hasZ ? n.zmin : -1 / 0, t[3] = n.xmax, t[4] = n.ymax, t[5] = n.hasZ ? n.zmax : 1 / 0, t;
}
function e(t, r) {
  const i = isFinite(t[2]) || isFinite(t[5]);
  return new n(i ? {
    xmin: t[0],
    xmax: t[3],
    ymin: t[1],
    ymax: t[4],
    zmin: t[2],
    zmax: t[5],
    spatialReference: r
  } : {
    xmin: t[0],
    xmax: t[3],
    ymin: t[1],
    ymax: t[4],
    spatialReference: r
  });
}
function m(n, t, r = i()) {
  return r[0] = n[0], r[1] = n[1], r[2] = n[2], r[3] = t[0], r[4] = t[1], r[5] = t[2], r;
}
function o(n, t) {
  n[0] = Math.min(n[0], t[0]), n[1] = Math.min(n[1], t[1]), n[2] = Math.min(n[2], t[2]), n[3] = Math.max(n[3], t[3]), n[4] = Math.max(n[4], t[4]), n[5] = Math.max(n[5], t[5]);
}
function f(n, t) {
  n[0] = Math.min(n[0], t[0]), n[1] = Math.min(n[1], t[1]), n[3] = Math.max(n[3], t[2]), n[4] = Math.max(n[4], t[3]);
}
function h(n, t) {
  n[0] = Math.min(n[0], t[0]), n[1] = Math.min(n[1], t[1]), n[2] = Math.min(n[2], t[2]), n[3] = Math.max(n[3], t[0]), n[4] = Math.max(n[4], t[1]), n[5] = Math.max(n[5], t[2]);
}
function c(n, t, r = 0, i = t.length / 3) {
  let a = n[0],
    u = n[1],
    e = n[2],
    m = n[3],
    o = n[4],
    f = n[5];
  for (let h = 0; h < i; h++) a = Math.min(a, t[r + 3 * h]), u = Math.min(u, t[r + 3 * h + 1]), e = Math.min(e, t[r + 3 * h + 2]), m = Math.max(m, t[r + 3 * h]), o = Math.max(o, t[r + 3 * h + 1]), f = Math.max(f, t[r + 3 * h + 2]);
  n[0] = a, n[1] = u, n[2] = e, n[3] = m, n[4] = o, n[5] = f;
}
function M(n, t, r, i) {
  n[0] = Math.min(n[0], n[0] + t), n[3] = Math.max(n[3], n[3] + t), n[1] = Math.min(n[1], n[1] + r), n[4] = Math.max(n[4], n[4] + r), n[2] = Math.min(n[2], n[2] + i), n[5] = Math.max(n[5], n[5] + i);
}
function x(n, t, r) {
  const i = t.length;
  let a = n[0],
    u = n[1],
    e = n[2],
    m = n[3],
    o = n[4],
    f = n[5];
  if (r) for (let h = 0; h < i; h++) {
    const n = t[h];
    a = Math.min(a, n[0]), u = Math.min(u, n[1]), e = Math.min(e, n[2]), m = Math.max(m, n[0]), o = Math.max(o, n[1]), f = Math.max(f, n[2]);
  } else for (let h = 0; h < i; h++) {
    const n = t[h];
    a = Math.min(a, n[0]), u = Math.min(u, n[1]), m = Math.max(m, n[0]), o = Math.max(o, n[1]);
  }
  n[0] = a, n[1] = u, n[2] = e, n[3] = m, n[4] = o, n[5] = f;
}
function l(n) {
  for (let t = 0; t < 6; t++) if (!isFinite(n[t])) return !1;
  return !0;
}
function s(n) {
  return n[0] >= n[3] ? 0 : n[3] - n[0];
}
function I(n) {
  return n[1] >= n[4] ? 0 : n[4] - n[1];
}
function N(n) {
  return n[2] >= n[5] ? 0 : n[5] - n[2];
}
function T(n) {
  const t = s(n),
    r = N(n),
    i = I(n);
  return Math.sqrt(t * t + r * r + i * i);
}
function E(n, t = [0, 0, 0]) {
  return t[0] = n[0] + s(n) / 2, t[1] = n[1] + I(n) / 2, t[2] = n[2] + N(n) / 2, t;
}
function F(n, t = [0, 0, 0]) {
  return t[0] = s(n), t[1] = I(n), t[2] = N(n), t;
}
function g(n) {
  return Math.max(s(n), N(n), I(n));
}
function y(n, t) {
  return t[0] >= n[0] && t[1] >= n[1] && t[2] >= n[2] && t[0] <= n[3] && t[1] <= n[4] && t[2] <= n[5];
}
function p(n, t, r) {
  return t[0] >= n[0] - r && t[1] >= n[1] - r && t[2] >= n[2] - r && t[0] <= n[3] + r && t[1] <= n[4] + r && t[2] <= n[5] + r;
}
function b(n, t) {
  return t[0] >= n[0] && t[1] >= n[1] && t[2] >= n[2] && t[3] <= n[3] && t[4] <= n[4] && t[5] <= n[5];
}
function z(n, t) {
  return Math.max(t[0], n[0]) <= Math.min(t[3], n[3]) && Math.max(t[1], n[1]) <= Math.min(t[4], n[4]) && Math.max(t[2], n[2]) <= Math.min(t[5], n[5]);
}
function V(n, t) {
  return null == t || z(n, t);
}
function Y(n, t, r, i, a = n) {
  return a[0] = n[0] + t, a[1] = n[1] + r, a[2] = n[2] + i, a[3] = n[3] + t, a[4] = n[4] + r, a[5] = n[5] + i, a;
}
function _(n, t, r = n) {
  const i = n[0] + s(n) / 2,
    a = n[1] + I(n) / 2,
    u = n[2] + N(n) / 2;
  return r[0] = i + (n[0] - i) * t, r[1] = a + (n[1] - a) * t, r[2] = u + (n[2] - u) * t, r[3] = i + (n[3] - i) * t, r[4] = a + (n[4] - a) * t, r[5] = u + (n[5] - u) * t, r;
}
function R(n, t) {
  return t[0] = n[0], t[1] = n[1], t[2] = n[2], t;
}
function j(n, t) {
  return t[0] = n[3], t[1] = n[4], t[2] = n[5], t;
}
function A(n, t, r = n) {
  return r[0] = t[0], r[1] = t[1], r[2] = t[2], r !== n && (r[3] = n[3], r[4] = n[4], r[5] = n[5]), r;
}
function G(n, t, r = n) {
  return r[3] = t[0], r[4] = t[1], r[5] = t[2], r !== n && (r[0] = n[0], r[1] = n[1], r[2] = n[2]), n;
}
function O(n, t) {
  return n[0] = t[0], n[1] = t[1], n[2] = t[2], n[3] = t[3], n[4] = t[4], n[5] = t[5], n;
}
function P(n) {
  return n ? O(n, C) : i(C);
}
function S(n, r) {
  return r || (r = t()), r[0] = n[0], r[1] = n[1], r[2] = n[3], r[3] = n[4], r;
}
function Z(n, t) {
  return n[0] = t[0], n[1] = t[1], n[2] = Number.NEGATIVE_INFINITY, n[3] = t[2], n[4] = t[3], n[5] = Number.POSITIVE_INFINITY, n;
}
function d(n, t, r, i, a) {
  return n[0] = t, n[1] = r, n[2] = Number.NEGATIVE_INFINITY, n[3] = i, n[4] = a, n[5] = Number.POSITIVE_INFINITY, n;
}
function q(n) {
  return 6 === n.length;
}
function w(n) {
  return 0 === s(n) && 0 === I(n) && 0 === N(n);
}
function B(n, t, r) {
  if (null == n || null == t) return n === t;
  if (!q(n) || !q(t)) return !1;
  if (r) {
    for (let i = 0; i < n.length; i++) if (!r(n[i], t[i])) return !1;
  } else for (let i = 0; i < n.length; i++) if (n[i] !== t[i]) return !1;
  return !0;
}
function k(n, t, r, i, u, e) {
  return a(n, t, r, i, u, e, H);
}
const v = r([-1 / 0, -1 / 0, -1 / 0, 1 / 0, 1 / 0, 1 / 0]),
  C = r([1 / 0, 1 / 0, 1 / 0, -1 / 0, -1 / 0, -1 / 0]),
  D = r([0, 0, 0, 0, 0, 0]),
  H = i();
export { l as allFinite, E as center, b as contains, y as containsPoint, p as containsPointWithMargin, i as create, I as depth, T as diameter, P as empty, B as equals, o as expandWithAABB, c as expandWithBuffer, x as expandWithNestedArray, M as expandWithOffset, f as expandWithRect, h as expandWithVec3, u as fromExtent, m as fromMinMax, Z as fromRect, d as fromRectValues, a as fromValues, j as getMax, R as getMin, N as height, z as intersects, V as intersectsClippingArea, q as is, w as isPoint, g as maximumDimension, C as negativeInfinity, Y as offset, v as positiveInfinity, _ as scale, O as set, G as setMax, A as setMin, F as size, e as toExtent, S as toRect, s as width, k as wrap, D as zero };